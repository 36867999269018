<template>
    <span :class="`badge badge-`+opt.style+(_events.click?' pointer':'')" @click="doClick">{{opt.label}}</span>
</template>

<script>
export default {
    props:{
        type: String,
        data: Object,
        status: [Number, String],
    },
    computed:{
        opt(){
            if(this.type=='contact'){
                return {
                    'R': {label:"Read",style:"success"},
                    'U': {label:"Unread",style:"warning"},
                }[this.status]
            }else if(this.type=='delegasi'){
                return {
                    'D': {label:"Upcoming",style:"info"},
                    'Y': {label:"Ongoing",style:"success"},
                    'N': {label:"Passed",style:"secondary"},
                }[this.status]
            }else if(this.type == 'sasaranRtu'){
                return {
                    'draft': {label:"Draft",style:"secondary"},
                    'purpose': {label:"Purpose",style:"info"},
                    'rejected': {label:"Rejected",style:"danger"},
                    'revise': {label:"Revise",style:"warning"},
                    'obsolete': {label:"Obsolete",style:"dark"},
                    'approved': {label:"Approved",style:"success"},
                }[this.status]
            } else if(this.type == 'pustakaRisiko'){
                return {
                    'Y': {label:"Active",style:"success"},
                    'N': {label:"Inactive",style:"warning"},
                }[this.status]
            }
            else{
                return {
                    '1': {label:"Active",style:"success"},
                    '2': {label:"Inactive",style:"warning"},
                    '3': {label:"Inactive",style:"warning"},
                }[this.status]
            }
        }
    },
    methods: {
        doClick(){
            this.$emit('click', this)
        }
    },
}
</script>